<template>
	<div class="my-account">
		<div class="header-menu va-row">
			<div class="va-row">
				<div class="flex container-ddh-menu xs12 with-bottom va-row">
					<div class="flex xs12 no-padding">
						<h1>Dashboard</h1>
					</div>
					<!-- <div class="flex xs6 no-padding text-right">
               <br><br>               
               <button
                  v-on:click="showSector(emptySector, 'add')"
                  class="btn btn-change">
                  ADICIONAR REGIÃO
               </button>
            </div> -->
				</div>
			</div>
			<div class="clearfix"></div>
		</div>

		<div class="container-dhh va-row">
			<div class="flex md12 xs12 no-padding">
				<h4></h4>
			</div>
			<div class="flex md12 xs12 no-padding">
				<div class="ui-grid">
					<div class="my-data-table va-row" id="cards">
						<div class="flex xs12 no-padding fiscal-notes">
							<div class="body-spacing">
								<div class="va-row">
									<div class="sets-list va-row">
										<div class="va-row">
											<!-- <div class="flex xs3 search-box">
                                  <label>Buscar pelo nome</label>
                                  <input-normal type="text" 
                                    label="Escola" 
                                    name="label" 
                                    :required="'true'" 
                                    :value="nameSchool" 
                                    :disabled="false" 
                                    maxlength="25"
                                    @input="searchName($event.target.value)" 
                                  />
                                  <button class="btn" @click="getSchools()">BUSCAR</button>                
                                </div> -->

											<div class="flex xs3">
												<div class="input-group">
													<label>Status</label>
													<select-normal
														:items="listStatus"
														:value="''"
														label="Selecione um status"
														name="typeName"
														v-model="selectStatus"
														@change="onChangeFilterStatus($event)"
													/>
												</div>
											</div>

											<div class="flex xs3">
												<div class="input-group">
													<label>Pagamento</label>
													<select-normal
														:items="listPayments"
														:value="''"
														label="Selecione um pagamento"
														name="typeName"
														v-model="selectPayments"
														@change="onChangeFilterPayment($event)"
													/>
												</div>
											</div>

											<div class="flex xs3">
												<label>Faturamento</label>
												<div class="input-group">
													<select-normal
														:items="listPaymentStatus"
														:value="''"
														label="Selecione um status"
														name="typeName"
														v-model="selectPaymentStatus"
														@change="onChangeFilterPaymentStatus($event)"
													/>
												</div>
											</div>

											<div class="flex xs3">
												<label>Forma de pagamento</label>
												<div class="input-group">
													<select-normal
														:items="listContracts"
														:value="''"
														label="Selecione um contrato"
														name="typeName"
														v-model="selectContracts"
														@change="onChangeFilterContracts($event)"
													/>
												</div>
											</div>
										</div>
									</div>

									<div class="sets-list va-row" v-if="list.length > 0">
										<div class="va-row header">
											<div class="flex xs2">Adoção</div>
											<div class="flex xs1">Criação</div>
											<div class="flex xs1">Pagamento</div>
											<div class="flex xs1">Aprovação</div>
											<div class="flex xs1">Produção</div>
											<div class="flex xs1">Entrega</div>
											<div class="flex xs1">Evento</div>
											<div class="flex xs1">Livros Gerados</div>
											<div class="flex xs1">Código de Rastreio</div>
											<div class="flex xs1 text-center">Turmas (Pedidos)</div>
										</div>

										<div
											class="va-row lines"
											v-for="item in list"
											:key="item.id"
										>
											<div class="flex xs2">
												{{ item.name }}
											</div>
											<div class="flex xs1" :class="item.created_class">
												{{ item.created }}
											</div>
											<div class="flex xs1" :class="item.payment_class">
												{{ item.payment }}
												<i
													class="fa fa-check"
													v-if="item.payment_status === true"
												></i>
											</div>
											<div
												class="flex xs1"
												:class="item.teacher_approval_class"
											>
												{{ item.teacher_approval }}
												<i
													class="fa fa-check"
													v-if="item.teacher_approval_status === true"
												></i>
											</div>
											<div class="flex xs1" :class="item.production_class">
												{{ item.production }}
												<i
													class="fa fa-check"
													v-if="item.production_status === true"
												></i>
											</div>
											<div class="flex xs1" :class="item.delivery_class">
												{{ item.delivery }}
												<i
													class="fa fa-check"
													v-if="item.delivery_status === true"
												></i>
											</div>
											<div class="flex xs1" :class="item.deadline_class">
												{{ item.deadline }}
												<i
													class="fa fa-check"
													v-if="item.deadline_status === true"
												></i>
											</div>
											<div class="flex xs1">
												{{ item.books_completed }}/{{ item.books_ordered }}
											</div>
											<div class="flex xs1">
												<a :href="item.tracking_code_url" target="_blank">{{
													item.tracking_code
												}}</a>
											</div>
											<div class="flex xs1 text-center">
												{{ item.grades }}
											</div>
										</div>
									</div>

									<!-- <div class="schedule-list" v-for="(item) in list.project.projects_schedule" :key="item.id">
                              <div class="va-row">
                                
                              

                                <div class="flex xs2">
                                  <div class="bullet purple">
                                    {{item.deadline}}
                                  </div>
                                  <div class="blocks purple">
                                    Evento da Adoção
                                  </div>
                                  <div class="description">
                                    Os livros estão prontos para utilização na escola
                                  </div>
                                </div>


                              </div>

                              

                              

                            </div> -->
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// import { required, minLength, between } from 'vuelidate/lib/validators'
	import { mask } from "vue-the-mask";
	import { mapGetters } from "vuex";

	// import VuesticTreeRoot from './VuesticTreeRoot.vue'
	// import VuesticTreeNode from './VuesticTreeNode'
	// import VuesticTreeCategory from './VuesticTreeCategory'

	const axios = require("axios");

	export default {
		name: "peoples",
		directives: { mask },
		// components: { VuesticTreeCategory, VuesticTreeNode, VuesticTreeRoot },
		computed: {
			...mapGetters({
				//userCredential: 'userCredential',
				consultantCredential: "consultantCredential",
				// ...
			}),
		},

		data() {
			return {
				filters: [],

				list: [],

				emptyPeople: {
					name: "",
					email: "",
					role_id: 20,
					username: "",
					client_id: 1,
					products: null,
					school_id: null,
					grade_id: [],
				},

				nameSchool: "",

				listStatus: [],
				listPayments: [],
				listPaymentStatus: [],
				listContracts: [],

				selectStatus: "",
				selectPayments: "",
				selectPaymentStatus: "",
				selectContracts: "",
			};
		},

		mounted() {
			const self = this;

			let config = {
				headers: {
					Authorization: "Bearer " + this.consultantCredential.token,
				},
			};

			axios
				.get(process.env.VUE_APP_ROOT_API + "/projects/filters", config)
				.then((response) => {
					self.filters = response.data.data;

					self.listStatus = response.data.data.status.map((s) => ({
						key: s.id,
						value: s.name,
					}));

					self.listPayments = response.data.data.payments.map((s) => ({
						key: s.id,
						value: s.name,
					}));

					self.listPaymentStatus = response.data.data.payment_status.map(
						(s) => ({
							key: s.id,
							value: s.name,
						})
					);

					self.listContracts = response.data.data.contracts.map((s) => ({
						key: s.id,
						value: s.name,
					}));
				})
				.catch((e) => {
					console.log(e);
					this.errors.push(e);
				});

			self.getGrades();
		},

		methods: {
			onChangeFilterStatus(event) {
				this.selectStatus = event.target.value;
				this.getGrades();
			},

			onChangeFilterPayment(event) {
				this.selectPayments = event.target.value;
				this.getGrades();
			},

			onChangeFilterPaymentStatus(event) {
				this.selectPaymentStatus = event.target.value;
				this.getGrades();
			},

			onChangeFilterContracts(event) {
				this.selectContracts = event.target.value;
				this.getGrades();
			},

			getGrades() {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.consultantCredential.token,
					},
				};

				let url =
					"/dash/projects?status=" +
					self.selectStatus +
					"&payments=" +
					self.selectPayments +
					"&payment_status=" +
					self.selectPaymentStatus +
					"&contracts=" +
					self.selectContracts;

				//let url = '/dash/projects';

				axios
					.get(process.env.VUE_APP_ROOT_API + url, config)
					.then((response) => {
						self.list = response.data.data.projects;
					})
					.catch((e) => {
						this.errors.push(e);
					});
			},
		},
	};
</script>
<style lang="scss" scoped>
	.lines {
		width: 100%;
	}

	.sets-list {
		font-size: 14px;
		width: 100%;

		.header {
			background-color: rgb(215, 209, 239);
			font-weight: bold;
			// text-align: center;
		}

		.lines:nth-child(odd) {
			background-color: rgb(248, 247, 252);
		}
		.lines:nth-child(even) {
			background-color: rgb(236, 235, 241);
		}

		.flex {
			&.success {
				background-color: rgb(97, 209, 135);
				color: white;
			}

			&.danger {
				background-color: rgb(255, 216, 3);
			}
		}
	}
	.schedule-list {
		text-align: center;

		.bullet {
			display: flex;
			width: 110px;
			height: 110px;
			border-radius: 60px;
			margin: 0 auto;
			text-align: center;
			align-items: center;
			justify-content: center;
			margin-bottom: 20px;

			&.blue {
				background-color: rgb(74, 144, 226);
				color: #fff;
			}

			&.purple {
				background-color: rgb(116, 106, 208);
				color: #fff;
			}

			&.purple-green {
				background-color: rgb(0, 207, 113);
				color: #fff;
			}
		}

		.blocks {
			padding: 10px 15px;
			margin-bottom: 15px;

			&.blue {
				background-color: rgb(74, 144, 226);
				color: #fff;
			}

			&.purple {
				background-color: rgb(116, 106, 208);
				color: #fff;
			}

			&.purple-green {
				background-color: rgb(0, 207, 113);
				color: #fff;
			}
		}
	}
	.btn-login {
		background-color: transparent;
		border: 0px;
		color: #4965bc;
	}
	.hide {
		display: none;
	}
	@media (max-width: 580px) {
		.btn-primary {
			width: 100%;
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 5px;
			}
		}
	}

	.fiscal-notes ::v-deep .form-group:last-child {
		margin-bottom: 10px !important;
	}

	.error-label {
		color: red;
		text-align: left;
		margin-bottom: 10px;
	}
	small {
		font-family: Roboto;
		font-size: 12px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #000000;
		text-indent: 15px;
		margin-top: -5px;
		display: block;
		margin-bottom: 10px;
		// padding-left: 15px;
	}
	h5 {
		font-family: "Nunito";
		font-size: 20px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
	}
	.address-info {
		margin-bottom: 15px;
	}
	.editing {
		margin-top: 15px;
	}
	.radio-button-wrapper {
		padding: 14px 11px;
		margin-top: 10px;

		@media (max-width: 990px) {
			padding: 20px 19px;
			margin-top: 10px;
		}
	}
	.header-menu-club {
		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}
	.add-new-card {
		border-radius: 10px;
		border: dashed 2px #2cd3d8;
		background-color: #ffffff;
		font-family: Nunito;
		font-size: 12px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 3.5;
		letter-spacing: 0.7px;
		text-align: center;
		color: #4965bc;
		margin-bottom: 20px;
		padding: 15px 0px;
		cursor: pointer;

		.plus {
			font-family: "Nunito";
			font-size: 35px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: 0.7px;
			text-align: center;
			color: #ffffff;
			width: 40px;
			height: 40px;
			transform: rotate(-180deg);
			border-radius: 40px;
			border: solid 1px #4965bc;
			background-color: #4965bc;
			display: block;
			margin: 0 auto;
			margin-top: 20px;
			line-height: 36px;
		}
	}

	h4 {
		font-family: "Nunito";
		font-size: 24px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
		margin-bottom: 0;
	}
	.header-menu {
		background-color: white;

		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}
	.my-data {
		/* Facebook bar */
		.facebook-tag {
			height: 65px;
			border-radius: 5px;
			background-color: #3a5998;
			color: white;
			padding: 14px 20px;
			margin-bottom: 20px;

			svg {
				font-size: 26px;
				margin-right: 0px;
				float: left;

				path {
					fill: #fff;
				}
			}

			.name {
				display: inline-block;
				margin-top: 1px;
				line-height: 37px;
			}

			.facebook-toggle {
				float: right;
				display: block;
				margin-top: 5px;
			}
		}
	}

	.change-password {
		border-radius: 10px;
		border: solid 2px #f0ebf5;
		background-color: #ffffff;
		padding: 24px 20px;
		position: relative;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 7px;
			font-family: "Roboto";

			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
		}

		h5 {
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #afa2ba;
			margin-top: 0px;
			margin-bottom: 0px;
		}

		.floated-icon {
			float: left;
			width: 36px;
			height: 36px;
			font-size: 30px;

			svg {
				position: relative;
				top: -5px;
				left: -3px;

				path {
					fill: #afa2ba;
				}
			}
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}
	}

	.my-data-table {
		h4 {
			margin-top: 50px;
			margin-bottom: 20px;
		}
	}
	.fiscal-notes {
		border-radius: 10px;
		border: solid 1px #f0ebf5;
		background-color: #ffffff;
		position: relative;
		border: solid 2px #f0ebf5;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 10px;
			font-family: "Roboto";
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		.body-spacing {
			padding: 30px 30px 10px 30px;

			@media (max-width: 990px) {
				padding: 25px 15px 15px 15px;
			}

			.btn-change-edit {
				border: none;
				color: #4965bc;
				background: transparent;
			}

			//  div:last-child{
			//   margin-bottom: 0px;
			// }
		}

		hr {
			border-top: solid 1px #f0ebf5;
			margin-top: 0px;
			margin-bottom: 0px;
		}

		.dual-inputs {
			padding: 0px 8px;
			margin-bottom: 20px;
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		.company-code {
			text-transform: capitalize;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}

		// .panel:last-child{
		//   margin-bottom: 0px;
		// }
	}

	.address-boxes {
		border-radius: 10px;
		border: solid 1px #f0ebf5;
		background-color: #ffffff;
		position: relative;
		border: solid 2px #f0ebf5;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 10px;
			font-family: "Roboto";
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		svg {
			path {
				fill: #afa2ba;
			}
		}

		.clube {
			svg {
				path {
					fill: #fff;
				}
			}
		}

		.body-spacing {
			padding: 30px 30px;

			@media (max-width: 990px) {
				padding: 25px 15px 15px 15px;
			}
		}

		hr {
			border-top: solid 2px #f0ebf5;
		}

		.dual-inputs {
			padding: 0px 8px;
			margin-bottom: 20px;
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}

		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}

		.address-row {
			padding: 16px 0px;
			position: relative;
			border-bottom: solid 1px #f0ebf5;
			b {
				font-size: 16px;
				color: #000000;
			}
			:last-child {
				border-bottom: none;
			}
		}
	}

	.address-row {
		position: relative;
	}

	.more-cards {
		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}
	}

	.panel {
		// height: 200px;
		border-radius: 10px;
		border: solid 2px #9378f0;
		background-color: #ffffff;
		overflow: hidden;
		margin-bottom: 20px;

		.header-panel {
			padding: 0px 20px;
			background-color: #9378f0;
			line-height: 33px;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #ffffff;
		}

		.clube img {
			margin-top: -5px;
		}

		b {
			font-size: 16px;
			color: #000000;
		}

		.panel-body {
			position: relative;
			padding: 10px 0px;
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: #afa2ba;
			margin: 0px !important;

			.flex.xs1 {
				-ms-flex-preferred-size: 8.33333%;
				flex-basis: 6.33333%;
				-webkit-box-flex: 0;
				-ms-flex-positive: 0;
				flex-grow: 0;
				max-width: 6.33333%;
			}

			b {
				font-family: Roboto;
				font-size: 16px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.31;
				letter-spacing: normal;
				color: #000000;
			}

			.editor {
				right: 18px;
				top: 20%;
			}
		}
	}

	.subscription-box {
		// padding: 30px !important;

		font-family: Roboto;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.31;
		letter-spacing: normal;
		color: #000000;

		.toggled {
			margin-top: 10px;
		}
	}
	.newsletter {
		// margin-top:40px;
		padding: 31px;
		border: solid 2px #f0ebf5;

		@media (max-width: 990px) {
			margin-top: 0px;
			padding: 15px;
		}
	}

	// Custom Component
	.v-switch-button {
		::v-deep &:before {
			content: "";
			display: block;
			position: relative;
			background-color: #fff;
			width: 6px;
			height: 6px;
			border-radius: 14.5px;
			margin-left: auto;
			margin-right: auto;
			top: 9px;
		}
	}

	.vue-js-switch {
		&.toggled {
			.v-switch-button {
				::v-deep &:before {
					content: "";
					display: block;
					position: relative;
					background-color: #7ed321;
					width: 6px;
					height: 6px;
					border-radius: 14.5px;
					margin-left: auto;
					margin-right: auto;
					top: 9px;
				}
			}
		}
	}

	.name-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}
	.lastname-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}
</style>
